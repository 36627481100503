
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { Config } from 'tradingmate-modules/src/environment'
import { BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Vue } from 'vue-property-decorator'
import BusinessBranchStatusUpdateDto
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchStatusUpdateDto'
import BusinessBranchWithFeedbackReadDto
  from 'tradingmate-modules/src/models/api/businesses/branches/Feedback/BusinessBranchWithFeedbackReadDto'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})
export default class MembershipsExpired extends Vue {
  public cancelledMembers: BusinessBranchWithFeedbackReadDto[] | null = null
  private loading = false;

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getCancelledMembers()
  }

  getCancelledMembers (): void {
    this.loading = true
    Services.API.Admin.Businesses.GetCancelledMembers()
      .then((members) => { this.cancelledMembers = members })
      .finally(() => { this.loading = false })
  }

  onCancel (id: string): void {
    const dto: BusinessBranchStatusUpdateDto = { Status: BusinessBranchStatus.Cancelled }
    Services.API.Admin.Businesses.StatusUpdate(id, dto)
      .then(() => {
        if (!this.cancelledMembers) return false
        const index = this.cancelledMembers.findIndex(x => x.BusinessBranch.BusinessBranchId === id)
        this.cancelledMembers.splice(index, 1)
      })
  }

  onRenew (id: string): void {
    this.$router.push({ name: 'Membership', params: { id: id } })
  }

  onViewProfile (slug: string): void {
    const frontendBaseUrl = Config.WebsiteUrls.Public
    const profilePath = `/members/${slug}`
    const url = frontendBaseUrl + profilePath
    window.open(url)
  }

  onEditProfile (id: string): void {
    this.$router.push({ name: 'Business Branch Edit', params: { id: id } })
  }
}

